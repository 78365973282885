import { normalizeBlockContentNodes } from '@blocks-helpers'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Seo from '@solid-ui-components/Seo'
import Tabs from '@solid-ui-components/Tabs'
import Layout from '@solid-ui-layout/Layout'
import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import './customStyleD.css'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import shutterstock_4 from './assets/shutterstock_4.jpg'
import shutterstock_5 from './assets/shutterstock_5.jpg'
import shutterstock_7 from './assets/shutterstock_7.jpg'

import Testimonials from '../Testimonials'

import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block07'

const ServiceEPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  const settings = {
    slidesToShow: 1,
    infinite: false,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    speed: 2000
  }

  const MyHTMLComponent = () => {
    return (
      <div className='service_spacer'>
        <div class='product1'>
          <div class='mycontainer'>
            <div class='sec1'>
              <h1>Floki Retail App (B2C)</h1>
              <p>
                Empower your retail business with a scalable e-commerce
                solution!
              </p>
            </div>
          </div>
        </div>
        <div class='discrptn'>
          <div class='mycontainer'>
            <p>
              In today's dynamic retail landscape, seizing the digital advantage
              isn't just an option; it's essential for growth and
              competitiveness. Welcome to Floki Retail App, where we redefine
              retail success. Our white label e-commerce solution unlocks the
              full potential of your business, giving you the edge you need to
              thrive
            </p>
          </div>
        </div>
        <div class='product1'>
          <div class='mycontainer'>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <img src={shutterstock_4} alt='product_img' />
                  {/* <img src="images/shutterstock_4.jpg" alt="product_img"> */}
                </div>
                <div class='colm6'>
                  <h4>Key Features :</h4>
                  <ul class='liststyle'>
                    <li>
                      <b>Custom Branding:</b> Seamlessly infuse your brand
                      identity into our white label mobile app, from your logo
                      to design elements, ensuring a consistent and
                      unforgettable customer journey.
                    </li>
                    <li>
                      <b>Scalable Growth:</b> As your business expands, Floki
                      Retail App, a product of our expert mobile app
                      development, effortlessly scales with you. It accommodates
                      rising product catalogs, increasing customer traffic, and
                      surging transactions, making it the perfect choice to
                      start an ecommerce business
                    </li>
                    <li>
                      <b>Customer-Centric Experience:</b> Offer an intuitive,
                      user-friendly online shopping app experience that keeps
                      customers coming back. With easy navigation, seamless
                      product discovery, and efficient order placement, you'll
                      attract and retain customers effortlessly.
                    </li>
                    <li>
                      <b>Data-Driven Decision-Making:</b> Gain valuable insights
                      into sales trends and customer behavior with our data
                      analytics. Leveraging our expertise in ecommerce app
                      development, you can make informed decisions and fine-tune
                      your retail strategy for success.
                    </li>
                    <li>
                      <b>Multi-Channel Mastery:</b> Reach your customers
                      wherever they are, whether on the web or mobile, expanding
                      your market presence and optimizing opportunities. Our
                      ecommerce app development company specializes in creating
                      versatile solutions for your business.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <h4>Benefits :</h4>
                  <ul class='liststyle'>
                    <li>
                      <strong>Increased Customer Loyalty: </strong> Our white
                      label mobile apps provide customers with a convenient and
                      efficient shopping experience, fostering customer loyalty.
                      Satisfied customers are more likely to return, making it
                      an ideal choice for those starting an ecommerce business
                    </li>
                    <li>
                      <strong>Streamlined operations:</strong> Optimize
                      inventory management, order tracking, and logistics using
                      our wholesale ecommerce ecommerce app.
                    </li>
                    <li>
                      <strong>Enhanced Customer Engagement: </strong>
                      Engage customers effectively through push notifications,
                      personalized offers, and interactive features within the
                      app, keeping them connected and informed. Our expertise in
                      mobile app development ensures a seamless online shopping
                      app experience.
                    </li>
                    <li>
                      <strong>Expanded market reach:</strong> xtend reach beyond
                      physical store locations with our ecommerce app
                      development company's solutions. Gain access to a broader
                      audience, including potential customers who prefer online
                      shopping or mobile app usage.
                    </li>
                    <li>
                      <strong>Competitive Edge: </strong>In a digitally driven
                      world, having an e-commerce app gives retailers a
                      competitive edge. It positions them as forward-thinking
                      and responsive to customer needs, making it an essential
                      tool for those starting an ecommerce business.
                    </li>
                    <li>
                      <strong>Improved Customer Service: </strong> Retailers can
                      offer better customer support through the app, providing
                      features like in-app chat, FAQs, and order tracking. This
                      enhances the overall online shopping app experience and
                      customer satisfaction.
                    </li>
                  </ul>
                </div>
                <div class='colm6'>
                  <img src={shutterstock_5} alt='product_img' />
                  {/* <img src="images/shutterstock_5.jpg" alt="product_img"> */}
                </div>
              </div>
            </div>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <img src={shutterstock_7} alt='product_img' />
                  {/* <img src="images/shutterstock_7.jpg" alt="product_img"> */}
                </div>
                <div class='colm6'>
                  <h4>Use Cases :</h4>
                  <ul class='liststyle'>
                    <li>
                      <strong>Fashion and Apparel Retailers: </strong> Showcase
                      your latest collections, offer secure online payments, and
                      provide shoppers with a seamless, fashion-forward
                      e-commerce experience
                    </li>
                    <li>
                      <strong>Electronics and Gadgets Shops: </strong> Manage
                      your inventory effectively, offer warranties, and engage
                      tech-savvy customers through a user-friendly online
                      platform
                    </li>
                    <li>
                      <strong>Beauty and Cosmetics Boutiques: </strong> Create
                      personalized shopping experiences, offer skincare
                      consultations, and provide easy checkout options to beauty
                      enthusiasts
                    </li>
                    <li>
                      <strong>Furniture and Home Decor Stores: </strong> Present
                      your products in detail and offer secure transactions,
                      expanding your reach and catering to customers seeking
                      home improvement solutions.
                    </li>
                    <li>
                      <strong>Health and Wellness Retailers: </strong> Provide
                      essential products, offer subscription services, and
                      optimize inventory for streamlined operations in the
                      health and wellness sector.
                    </li>
                    <li>
                      <strong>And Many More: </strong> Whatever your retail
                      niche, Floki Retail App is tailored to suit your unique
                      requirements and empower your success in the digital
                      retail landscape.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Helmet>
        <title>
          Retail eCommerce App | B2C eCommerce Platform | Floki Solutions
        </title>
        <meta
          name='google-site-verification'
          content='jvulVjeMbr8Qb07aPAbFxWgBuwOl_Q2A9EvJoemO1co'
        />
        <meta
          name='description'
          content='Need a wholesale ecommerce app or b2b ecommerce platform? Our ecommerce solutions for wholesale distribution make it easy to manage your operations and grow your business.'
        />
        <meta
          name='keywords'
          content='wholesale ecommerce app, wholesale ecommerce app, b2b ecommerce platform, wholesale ecommerce platform, b2b ecommerce application, ecommerce for wholesale distribution'
        />

        <meta
          property='og:title'
          content='Wholesale eCommerce App | B2B eCommerce Platform | Floki Solutions'
        />
        <meta
          property='og:description'
          content='Need a wholesale ecommerce app or b2b ecommerce platform? Our ecommerce solutions for wholesale distribution make it easy to manage your operations and grow your business.'
        />

        <meta
          property='og:url'
          content='https://flokisolutionsmain.gatsbyjs.io/floki-retail-app-b2c/'
        />
        <meta property='og:type' content='website' />
      </Helmet>

      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}

      <Header content={content['header']} />

      <MyHTMLComponent></MyHTMLComponent>

      <Tabs space={4} variant='pill'>
        <FeatureTabOne content={content['feature-tab-one']} />
        <FeatureTabTwo content={content['feature-tab-two']} reverse />
        <FeatureTabThree content={content['feature-tab-three']} />
      </Tabs>
      <Testimonials />

      {/* <Divider space='5' /> */}
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query ServiceEPageContent {
    allBlockContent(
      filter: { page: { in: ["site/floki-retail-app-b2c", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default ServiceEPage
